body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: rgb(3,19,32);
    font-family: PingFangSC-Regular;
    color: #A1BDCE;
    letter-spacing: 0;
    line-height: 1;
    font-size: 20px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.new:hover {
    cursor: pointer;
}
.edit-content{
    margin: 20px 0 0 20px;
    padding: 5px 32px;
    height: calc(100vh - 110px);
    /* width: calc( 100% - 120px); */
    width: 100%;
    background: #12222F;
    display: inline-block;
    position: fixed;
    bottom: 0;
}

.center{
    text-align: center;
}

.spin-container-half{
    text-align: center;
    /* background: rgba(224, 217, 217, 0.2); */
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 30px 50px;
    margin: 200px 0;
    z-index: 600;
}
.ant-spin-dot-item{
    background-color: #dbe0e5 !important;
}
.ant-spin-text{
    color: rgba(255, 255, 254,0.7);
}

/* 隐藏IE浏览器滚动条 */
.main-table, 
.sidebar-arrow,
.building-scroll-view,
.inspection-content-edit .items .flow,
.inspection-content-edit .contents .flow,
.right-content-project-456,
.brief-content{
    /*隐藏滚动条，当IE下溢出，仍然可以滚动*/
    -ms-overflow-style:none;
}

/* 自定义火狐浏览器滚动条 */


