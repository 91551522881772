.headerContainer {
    display: flex;
    justify-content: space-between;
    padding: 15px 30px 15px 10px;
}

.headerTitle {
    font-family: PingFangSC-Regular;
    font-size: 24px;
    color: #FFFFFF;
    letter-spacing: 0;
    line-height: 24px;
}

.headerContainer .add_img{
    height: 30px;
    width: 30px;
    margin: 0 20px;
}
.headerContainer .ant-input {
    background: #12222F;
    border-width: 0;
    border-bottom: solid 1px #394753;
    color: #A1BDCE;
}

.headerContainer .ant-input:hover {
    background: #12222F;
    border-width: 0;
    border-bottom: solid 1px #394753;
    color: #A1BDCE;
}
.headerContainer .ant-input-group-addon{
    background-color: #12222F;
    border-width: 0;
}
.searchIcon{
    color: #FFFFFF;
}
.headerContainer .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled){
    border-width: 0;
}


/******************下拉远程选择过滤*****************/
.remoterSelectFilter-container{
    float: left;
    width: 200px;
    margin-left: 30px;
}

.remoterSelectFilter-container .ant-select-selection,
.plan-type-select .ant-select-selection{
    background-color: #12222F;
    border-width: 0;
    border-bottom: solid 1px #394753;
    color: #A1BDCE;
}
.plan-type-select{
    display: inline-block;
    margin-left: 40px;
}
.plan-type-select .ant-select-selection__placeholder {
    margin-top: -15px !important;
}

